import { Injectable } from '@angular/core';
import {
  AddHlsVideoProperties,
  AddVideoProperties,
  AddVideoToNewLessonProperties,
  AddVimeoBasicVideoProperties,
  AddYoutubeVideoProperties,
  AiLessonProperties,
  AllPost,
  Chapter,
  ChapterCreateProperties,
  ChapterData,
  ChapterProperties,
  ChapterStateProperties,
  Course,
  Lecture,
  LessonContentProperties,
  LessonCreateProperties,
  LessonStateProperties,
  LessonTypeProperties,
  LessonUpdateableProperties,
  MoveLessonProperty,
  PullVideoFromLinkProperties,
  ReorderChapterPayload,
  ReorderLessonProperties,
  SupportedVideoLinkProperties,
  UpdateFilePriorities,
} from '@memberspot/shared/model/course';
import {
  AiDataType,
  ThumbnailProperties,
  UploadFile,
  VideoProvider,
} from '@memberspot/shared/model/file';
import { RecursivePartial } from '@memberspot/shared/model/types';
import { tap } from 'rxjs';

import { ChaptersStore } from '../state/chapters.store';
import { ChapterAdminApiService } from './chapter-admin.api.service';

@Injectable({ providedIn: 'root' })
export class ChapterAdminService {
  constructor(
    private _chapterApiService: ChapterAdminApiService,
    private _chaptersStore: ChaptersStore,
  ) {}

  sync(schoolId: string, courseId: string) {
    this._chaptersStore.setLoading(true);
    this._chaptersStore.reset();

    return this._chapterApiService.getChapters(schoolId, courseId).pipe(
      tap((chapters) => {
        this._chaptersStore.setLoading(false);
        this._chaptersStore.set(chapters || []);
      }),
    );
  }

  reloadLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
  ) {
    return this._chapterApiService
      .getLesson(schoolId, courseId, chapterId, lessonId)
      .pipe(
        tap((lesson) => {
          this.updatePostInStore(chapterId, lessonId, lesson);
        }),
      );
  }

  createChapter(
    schoolId: string,
    courseId: string,
    properties: ChapterCreateProperties,
  ) {
    return this._chapterApiService
      .createChapter(schoolId, courseId, properties)
      .pipe(
        tap((chapter) => {
          this._chaptersStore.add(chapter);
        }),
      );
  }

  deleteChapter(schoolId: string, courseId: string, chapterId: string) {
    return this._chapterApiService
      .deleteChapter(schoolId, courseId, chapterId)
      .pipe(
        tap(() => {
          this._chaptersStore.remove(chapterId);
        }),
      );
  }

  duplicateChapter(
    schoolId: string,
    courseId: string,
    chapterId: string,
    targetCourseId?: string,
  ) {
    return this._chapterApiService
      .duplicateChapter(schoolId, courseId, chapterId, targetCourseId)
      .pipe(
        tap((chapter) => {
          if (courseId === targetCourseId) {
            this._chaptersStore.add(chapter);
          }
        }),
      );
  }

  setChapterThumbnail(
    schoolId: string,
    courseId: string,
    chapterId: string,
    thumbnailId: string,
    thumbnail: ThumbnailProperties,
  ) {
    return this._chapterApiService
      .setChapterThumbnail(
        schoolId,
        courseId,
        chapterId,
        thumbnailId,
        thumbnail,
      )
      .pipe(
        tap((chapter: Chapter) => {
          this._chaptersStore.upsert(chapter.id, chapter);
        }),
      );
  }

  updateChapter(
    schoolId: string,
    courseId: string,
    chapterId: string,
    update: Partial<Chapter> | RecursivePartial<ChapterData>,
  ) {
    const { id, posts, ...properties } = update;

    return this._chapterApiService
      .updateChapter(
        schoolId,
        courseId,
        chapterId,
        properties as ChapterProperties,
      )
      .pipe(
        tap((chapter) => {
          this._chaptersStore.upsert(chapter.id, chapter);
        }),
      );
  }

  updateChapterState(
    schoolId: string,
    courseId: string,
    chapterId: string,
    properties: ChapterStateProperties,
  ) {
    return this._chapterApiService
      .updateChapterState(schoolId, courseId, chapterId, properties)
      .pipe(
        tap((chapter) => {
          this._chaptersStore.upsert(chapter.id, chapter);
        }),
      );
  }

  updateChapterPropertiesAfterCourseUpdated(course: Course) {
    this._chaptersStore.updateCoursePropertiesInChapters(course);
  }

  reorderChapters(
    schoolId: string,
    courseId: string,
    { id, newIndex, previousIndex }: ReorderChapterPayload,
  ) {
    // get the current state of chapters
    const currentChapters = this._chaptersStore.getValue().entities ?? {};

    // perform optimistic update
    const chaptersArray = [...Object.values(currentChapters)].sort(
      (a, b) => a.priority - b.priority,
    );

    const [movedChapter] = chaptersArray.splice(previousIndex, 1);

    chaptersArray.splice(newIndex, 0, movedChapter);

    // update the store optimistically
    const updatedPriorities = chaptersArray.map((chapter) => ({
      id: chapter.id,
      priority: chapter.priority,
    }));

    const chaptersRecord: Record<string, Chapter> = updatedPriorities.reduce(
      (acc, chapter) => {
        acc[chapter.id] = {
          ...currentChapters[chapter.id],
          priority: updatedPriorities.indexOf(chapter),
        };

        return acc;
      },
      {} as Record<string, Chapter>,
    );

    this._chaptersStore.set(chaptersRecord);

    return this._chapterApiService
      .reorderChapters(schoolId, courseId, {
        id,
        previousIndex: previousIndex,
        newIndex: newIndex,
      })
      .pipe(
        tap((chapters) => {
          this._chaptersStore.set(chapters);
        }),
      );
  }

  createLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonProperties: LessonCreateProperties,
  ) {
    return this._chapterApiService
      .createLesson(schoolId, courseId, chapterId, lessonProperties)
      .pipe(
        tap((post) => {
          this.updatePostInStore(chapterId, post.id as string, post);
        }),
      );
  }

  deleteLesson(
    schoolId: string,
    couseId: string,
    chapterId: string,
    lessonId: string,
  ) {
    return this._chapterApiService
      .deleteLesson(schoolId, couseId, chapterId, lessonId)
      .pipe(
        tap(() => {
          this._chaptersStore.update(chapterId, (chapter) => {
            const posts = { ...chapter.posts.filter((l) => l.id !== lessonId) };

            return {
              ...chapter,
              posts,
            };
          });
        }),
      );
  }

  duplicateLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
  ) {
    return this._chapterApiService
      .duplicateLesson(schoolId, courseId, chapterId, lessonId)
      .pipe(
        tap((duplicatedLesson) => {
          this.updatePostInStore(
            chapterId,
            duplicatedLesson.id as string,
            duplicatedLesson,
          );
        }),
      );
  }

  updateLessonState(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    properties: LessonStateProperties,
  ) {
    return this._chapterApiService
      .updateLessonState(schoolId, courseId, chapterId, lessonId, properties)
      .pipe(
        tap((chapter) => {
          this._chaptersStore.upsert(chapter.id, chapter);
        }),
      );
  }

  updateLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    properties: LessonUpdateableProperties,
  ) {
    return this._chapterApiService
      .updateLesson(schoolId, courseId, chapterId, lessonId, properties)
      .pipe(
        tap((lesson) => {
          this.updatePostInStore(chapterId, lessonId, lesson);
        }),
      );
  }

  unlinkExam(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
  ) {
    return this._chapterApiService
      .unlinkExam(schoolId, courseId, chapterId, lessonId)
      .pipe(
        tap((lesson) => {
          this.updatePostInStore(chapterId, lessonId, lesson);
        }),
      );
  }

  linkExam(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    examId: string,
  ) {
    return this._chapterApiService
      .linkExam(schoolId, courseId, chapterId, lessonId, examId)
      .pipe(
        tap((lesson) => {
          this.updatePostInStore(chapterId, lessonId, lesson);
        }),
      );
  }

  unlinkCertificate(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
  ) {
    return this._chapterApiService
      .unlinkCertificate(schoolId, courseId, chapterId, lessonId)
      .pipe(
        tap((lesson) => {
          this.updatePostInStore(chapterId, lessonId, lesson);
        }),
      );
  }

  linkCertificate(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    certificateId: string,
  ) {
    return this._chapterApiService
      .linkCertificate(schoolId, courseId, chapterId, lessonId, certificateId)
      .pipe(
        tap((lesson) => {
          this.updatePostInStore(chapterId, lessonId, lesson);
        }),
      );
  }

  changeLessonType(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: LessonTypeProperties,
  ) {
    return this._chapterApiService
      .changeLessonType(schoolId, courseId, chapterId, lessonId, data)
      .pipe(
        tap((lesson) => {
          this.updatePostInStore(chapterId, lessonId, lesson);
        }),
      );
  }

  updateLessonContent(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    content: LessonContentProperties,
  ) {
    return this._chapterApiService
      .updateLessonContent(schoolId, courseId, chapterId, lessonId, content)
      .pipe(
        tap((lesson) => {
          this.updatePostInStore(chapterId, lessonId, lesson);
        }),
      );
  }

  updatePostInStore(
    chapterId: string,
    postId: string,
    post: RecursivePartial<AllPost>,
  ) {
    this._chaptersStore.update(chapterId, (chapter) =>
      this._upsertPostInChapterPosts(chapter, postId, post as AllPost),
    );
  }

  updateAiDataForPostVideoInStore(
    chapterId: string,
    postId: string,
    aiData?: AiDataType | null,
  ) {
    this._chaptersStore.update(chapterId, (chapter) =>
      this._updateAiDataForPostVideoInStore(chapter, postId, aiData),
    );
  }

  moveLessonToChapter(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    targetChapterId: string,
    properties: MoveLessonProperty,
  ) {
    return this._chapterApiService
      .moveLessonToChapter(
        schoolId,
        courseId,
        chapterId,
        lessonId,
        targetChapterId,
        properties,
      )
      .pipe(
        tap((chapters) => {
          this._chaptersStore.set(chapters);
        }),
      );
  }

  moveLessonInsideChapter(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    properties: ReorderLessonProperties,
  ) {
    return this._chapterApiService
      .moveLessonInsideChapter(
        schoolId,
        courseId,
        chapterId,
        lessonId,
        properties,
      )
      .pipe(
        tap((chapter) => {
          this._chaptersStore.upsert(chapter.id, chapter);
        }),
      );
  }

  setLessonThumbnail(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    thumbnailId: string,
    thumbnail: ThumbnailProperties,
  ) {
    return this._chapterApiService
      .setLessonThumbnail(
        schoolId,
        courseId,
        chapterId,
        lessonId,
        thumbnailId,
        thumbnail,
      )
      .pipe(
        tap((lesson: RecursivePartial<AllPost>) => {
          this.updatePostInStore(chapterId, lessonId, lesson);
        }),
      );
  }

  resetLessonThumbnail(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    thumbnailId: string,
  ) {
    return this._chapterApiService
      .resetLessonThumbnail(
        schoolId,
        courseId,
        chapterId,
        lessonId,
        thumbnailId,
      )
      .pipe(
        tap((lesson: RecursivePartial<AllPost>) => {
          this.updatePostInStore(chapterId, lessonId, lesson);
        }),
      );
  }

  addFile(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    uploadedFile: UploadFile,
    type: 'file' | 'audio' | 'pdf' = 'file',
  ) {
    const file = {
      ...uploadedFile,
    };

    delete file.addedAt;

    return this._chapterApiService
      .addFile(schoolId, courseId, chapterId, lessonId, {
        ...file,
        type,
      })
      .pipe(
        tap((lesson) => {
          this.updatePostInStore(chapterId, lessonId, lesson);
        }),
      );
  }

  deleteFile(
    schoolId: string,
    courseId: string,
    chapterId: string,
    postId: string,
    file: UploadFile,
    type: 'file' | 'audio' | 'pdf' = 'file',
  ) {
    return this._chapterApiService
      .deleteFile(schoolId, courseId, chapterId, postId, {
        ...file,
        type,
      })
      .pipe(
        tap((lesson) => {
          this.updatePostInStore(chapterId, postId, lesson);
        }),
      );
  }

  sortFiles(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: UpdateFilePriorities,
  ) {
    return this._chapterApiService
      .sortFiles(schoolId, courseId, chapterId, lessonId, data)
      .pipe(
        tap((lesson) => {
          this.updatePostInStore(chapterId, lessonId, lesson);
        }),
      );
  }

  deleteVideoFromLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
  ) {
    return this._chapterApiService
      .deleteVideoFromPost(schoolId, courseId, chapterId, lessonId)
      .pipe(
        tap((lesson) => this.updatePostInStore(chapterId, lessonId, lesson)),
      );
  }

  addVideoToExistingLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: AddVideoProperties,
  ) {
    return this._chapterApiService
      .addVideoToLesson(schoolId, courseId, chapterId, lessonId, data)
      .pipe(
        tap((lesson) => this.updatePostInStore(chapterId, lessonId, lesson)),
      );
  }

  pullVideoFromLinkToLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    properties: PullVideoFromLinkProperties,
  ) {
    return this._chapterApiService
      .pullVideoFromLinkToLesson(
        schoolId,
        courseId,
        chapterId,
        lessonId,
        properties,
      )
      .pipe(
        tap((lesson) => this.updatePostInStore(chapterId, lessonId, lesson)),
      );
  }

  addNewVideoAndLesson(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    data: AddVideoToNewLessonProperties,
  ) {
    return this._chapterApiService
      .addVideoWithNewLesson(schoolId, courseId, chapterId, lessonId, data)
      .pipe(
        tap((lesson) => this.updatePostInStore(chapterId, lessonId, lesson)),
      );
  }

  addVideoOverLink(
    schoolId: string,
    courseId: string,
    chapterId: string,
    postId: string,
    provider: VideoProvider,
    properties: SupportedVideoLinkProperties,
  ) {
    switch (provider) {
      case VideoProvider.YOUTUBE:
        return this._chapterApiService
          .addYoutubeVideoToLesson(
            schoolId,
            courseId,
            chapterId,
            postId,
            properties as AddYoutubeVideoProperties,
          )
          .pipe(
            tap((lesson) => this.updatePostInStore(chapterId, postId, lesson)),
          );
      case VideoProvider.HLS_LINK:
        return this._chapterApiService
          .addHlsVideoToLesson(
            schoolId,
            courseId,
            chapterId,
            postId,
            properties as AddHlsVideoProperties,
          )
          .pipe(
            tap((lesson) => this.updatePostInStore(chapterId, postId, lesson)),
          );
      case VideoProvider.VIMEO:
        return this._chapterApiService
          .addVimeoBasicVideoToLesson(
            schoolId,
            courseId,
            chapterId,
            postId,
            properties as AddVimeoBasicVideoProperties,
          )
          .pipe(
            tap((lesson) => this.updatePostInStore(chapterId, postId, lesson)),
          );
      default:
        throw Error('Unsupported video provider');
    }
  }

  retryVideoConversion(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
  ) {
    return this._chapterApiService
      .retryConversion(schoolId, courseId, chapterId, lessonId)
      .pipe(
        tap((lesson) => this.updatePostInStore(chapterId, lessonId, lesson)),
      );
  }

  updateVideoDuration(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    duration: number,
  ) {
    return this._chapterApiService
      .updateVideoDuration(schoolId, courseId, chapterId, lessonId, {
        duration,
      })
      .pipe(
        tap((lesson) => this.updatePostInStore(chapterId, lessonId, lesson)),
      );
  }

  updateLessonAiProperties(
    schoolId: string,
    courseId: string,
    chapterId: string,
    lessonId: string,
    properties: AiLessonProperties,
  ) {
    return this._chapterApiService
      .updateLessonAiProperties(
        schoolId,
        courseId,
        chapterId,
        lessonId,
        properties,
      )
      .pipe(
        tap((lesson) => this.updatePostInStore(chapterId, lessonId, lesson)),
      );
  }

  private _upsertPostInChapterPosts(
    chapter: Chapter,
    postId: string,
    post: AllPost,
  ): Chapter {
    let posts = [...chapter.posts];

    const postIndex = (posts || []).findIndex((p) => p.id === postId);

    if (postIndex === -1) {
      posts = [...posts, post];
    } else {
      posts[postIndex] = post;
    }

    return {
      ...chapter,
      posts,
    };
  }

  private _updateAiDataForPostVideoInStore(
    chapter: Chapter,
    postId: string,
    aiData?: AiDataType | null,
  ) {
    const posts = [...chapter.posts];

    const postIndex = (posts || []).findIndex((p) => p.id === postId);

    if (postIndex === -1) {
      return chapter;
    }

    let post = posts[postIndex] as Lecture;

    if (!post) {
      return chapter;
    }

    if (post.video) {
      const video = { ...post.video, aiData };

      post = { ...post, video };
    }

    posts[postIndex] = post;

    return {
      ...chapter,
      posts,
    };
  }
}
